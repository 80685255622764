<template>
  <v-row>
    <v-col cols="12">
      <div class="card-title m-0 capitalize">New Calculation</div>
    </v-col>
    <v-col cols="12" lg="12" md="12">
      <v-row>
        <v-col cols="12" md="3">
          <!-- test -->
          <crypto-currency-card
            bg-card-color="bg-primary"
            heading="Sample"
            sub-heading="Data Exposure"
            desc="Data Klasifikasi Bangunan"
            main-icon-text-color="text-blue-400"
            main-icon-name="mdi-numeric-9-plus-circle-outline"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-danger"
            heading="Tutorial"
            desc="Cara Penggunaan Aplikasi"
            sub-heading="Download"
            main-icon-text-color="text-red-400"
            main-icon-name="mdi-numeric-9-plus-circle-outline"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-success"
            heading="Learning Model Terakhir"
            sub-heading="1 Februari 2021"
            desc="Coansole Execution Update"
            main-icon-text-color="text-green-300"
            main-icon-name="mdi-numeric-9-plus-circle-outline"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-warning"
            heading="Total Bencana Gempa"
            sub-heading="0"
            desc="1 TAHUN TERAKHIR"
            main-icon-text-color="text-red-300"
            main-icon-name="mdi-numeric-9-plus-circle-outline"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="12" md="12">
      <base-card>
        <v-card-title style="display: block; font-weight: 100; font-size: 1rem">
          Summary Verifikasi Data</v-card-title
        >
        <v-card-text>
          <v-row class="text-center">
            <v-col cols="12" lg="4" md="6">
              <label for="" class="font-bold">Formulir Perhitungan Baru</label>
              <v-form ref="form" v-model="formCal" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="exposure"
                      label="Data Exposure and Klasifikasi (CSV)"
                      type="file"
                      :rules="[
                        (v) =>
                          !!v || 'Data Exposure and Klasifikasi is required',
                      ]"
                      required
                    />
                    <v-select
                      v-model="metode"
                      :items="[
                        { name: 'Kriging', val: 'KRIGING' },
                        { name: 'k Nearest Neighbors', val: 'K_NEAREST_NEIGHBORS' },
                        { name: 'Neural Network', val: 'NEURAL_NETWORK' },
                        { name: 'Decision Tree', val: 'DECISION_TREE' },
                      ]"
                      item-text="name"
                      item-value="val"
                      :rules="[(v) => !!v || 'Jenis Metode is required']"
                      label="Jenis Metode"
                      required
                    />
                    <v-select
                      v-model="clustering"
                      :items="[
                        { name: 'Yes', val: '1' },
                        { name: 'No', val: '2' },
                      ]"
                      item-text="name"
                      item-value="val"
                      :rules="[(v) => !!v || 'Clustering is required']"
                      label="Clustering"
                      required
                    />
                  </v-col>
                </v-row>
                <br />
                <v-btn color="success" class="mr-4" @click="prosesHitung">
                  Hitung
                </v-btn>
                <v-btn color="primary" class="mr-4"> Export (CSV) </v-btn>
              </v-form>
            </v-col>
            <v-col cols="12" lg="8" md="6">
              <label for="" class="font-bold"
                >Aplikasi Pemodelan Katastropik Untuk Pemodelan Bencana</label
              >
              <br /><br />
              <v-row>
                <v-col cols="12">
                  <label for="" class="font-bold"
                    >Peta Sebaran Historikal Gempa</label
                  >
                  <br /><label for=""
                    >Tiap titik mewakili kejadian Gempa, sedangakan ukuran
                    diameter lingkaran meunjukan tingkat kekuatan gempa.</label
                  >
                  <div id="map" style="height: 500px; width: 100%"></div>
                </v-col>
              </v-row>
              <v-row v-if="heatMap">
                <v-col cols="12">
                  <label for="" class="font-bold"
                    >Peta Sebaran Risiko Gempa</label
                  >
                  <br /><label for=""
                    >Tiap area memiliki kadar warna merah yang ber beda-beda,
                    semakin mendekati warna merah berarti tingkat risiko gempa
                    semakin tinggi.</label
                  >
                  <div id="map2" style="height: 500px; width: 100%"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import CryptoCurrencyCard from "@/components/card/CryptoCurrencyCard";
import { loadModules } from "esri-loader";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "new_calculation",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "New Calculation",
  },
  components: {
    CryptoCurrencyCard,
  },
  computed: {
    ...mapGetters(["GEMAS_BACKEND"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      exposure: null,
      metode: "",
      clustering: "",
      formCal: true,
      heatMap: false,
    };
  },
  mounted() {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/CSVLayer",
        "esri/widgets/Legend",
        "esri/layers/GeoJSONLayer",
      ],
      { css: true }
    ).then(([Map, MapView, CSVLayer, Legend, GeoJSONLayer]) => {
      // let map = new Map({
      //   basemap: "streets",
      // });
      // let view = new MapView({
      //   container: "map",
      //   map: map,
      //   zoom: 4,
      //   center: [15, 65],
      // });

      // const url2 = this.GEMAS_BACKEND + "kejadian_gempa/getcsv/sample.csv";
      const url = this.GEMAS_BACKEND + "kejadian_gempa/getGeoJson";
      // Paste the url into a browser's address bar to download and view the attributes
      // in the CSV file. These attributes include:
      // * mag - magnitude
      // * type - earthquake or other event such as nuclear test
      // * place - location of the event
      // * time - the time of the event

      const template = {
        content: "Magnitude {mag} on {time}.",
      };

      // const template = {
      //   title: "Earthquake Info",
      //   content: "Magnitude {mag} on {time}",
      //   fieldInfos: [
      //     {
      //       fieldName: 'time',
      //       format: {
      //         dateFormat: 'short-date-short-time'
      //       }
      //     }
      //   ]
      // };

      // The heatmap renderer assigns each pixel in the view with
      // an intensity value. The ratio of that intensity value
      // to the maxPixel intensity is used to assign a color
      // from the continuous color ramp in the colorStops property

      /* HEAT MAP **/
      // const renderer2 = {
      //   type: "heatmap",
      //   colorStops: [
      //     { color: "rgba(63, 40, 102, 0)", ratio: 0 },
      //     { color: "#472b77", ratio: 0.083 },
      //     { color: "#4e2d87", ratio: 0.166 },
      //     { color: "#563098", ratio: 0.249 },
      //     { color: "#5d32a8", ratio: 0.332 },
      //     { color: "#6735be", ratio: 0.415 },
      //     { color: "#7139d4", ratio: 0.498 },
      //     { color: "#7b3ce9", ratio: 0.581 },
      //     { color: "#853fff", ratio: 0.664 },
      //     { color: "#a46fbf", ratio: 0.747 },
      //     { color: "#c29f80", ratio: 0.83 },
      //     { color: "#e0cf40", ratio: 0.913 },
      //     { color: "#ffff00", ratio: 1 },
      //   ],
      //   maxPixelIntensity: 25,
      //   minPixelIntensity: 0,
      // };

      // const layer2 = new CSVLayer({
      //   url: url2,
      //   title: "Earthquakes",
      //   copyright: "GEMAS",
      //   popupTemplate: template,
      //   renderer: renderer2,
      // });

      // const map2 = new Map({
      //   basemap: "gray-vector",
      //   layers: [layer2],
      // });

      // const view2 = new MapView({
      //   container: "map2",
      //   center: [107, -7],
      //   zoom: 6,
      //   map: map2,
      // });

      // view2.ui.add(
      //   new Legend({
      //     view: view2,
      //   }),
      //   "bottom-left"
      // );

      /* Point GeoJson **/

      const renderer = {
        type: "simple",
        field: "mag",
        symbol: {
          type: "simple-marker",
          color: "orange",
          outline: {
            color: "white",
          },
        },
        visualVariables: [
          {
            type: "size",
            field: "mag",
            stops: [
              {
                value: 2.5,
                size: "4px",
              },
              {
                value: 8,
                size: "40px",
              },
            ],
          },
        ],
      };

      const geojsonLayer = new GeoJSONLayer({
        url: url,
        copyright: "GEMAS",
        popupTemplate: template,
        renderer: renderer, //optional
      });

      const map = new Map({
        basemap: "gray-vector",
        layers: [geojsonLayer],
      });

      const view = new MapView({
        container: "map",
        center: [108, -7],
        zoom: 6,
        map: map,
      });
    });
  },
  methods: {
    prosesHitung() {
      if (this.$refs.form.validate()) {
        let postData = new FormData();
        postData.append("exposure", this.exposure);
        postData.append("metode", this.metode);
        postData.append("clustering", this.clustering);
        axios
          .post(this.GEMAS_BACKEND + "perhitungan/api_add", postData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            console.log(res.status);
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
        this.heatMap = true;
        loadModules(
          [
            "esri/Map",
            "esri/views/MapView",
            "esri/layers/CSVLayer",
            "esri/widgets/Legend",
            "esri/layers/GeoJSONLayer",
          ],
          { css: true }
        ).then(([Map, MapView, CSVLayer, Legend, GeoJSONLayer]) => {
          const url2 =
            this.GEMAS_BACKEND +
            "kejadian_gempa/getcsv?m=" +
            this.metode +
            "&c=" +
            this.clustering;

          const template = {
            content: "Magnitude {mag} on {time}.",
          };

          /* HEAT MAP **/
          const renderer2 = {
            type: "heatmap",
            colorStops: [
              { color: "rgba(63, 40, 102, 0)", ratio: 0 },
              { color: "#472b77", ratio: 0.083 },
              { color: "#4e2d87", ratio: 0.166 },
              { color: "#563098", ratio: 0.249 },
              { color: "#5d32a8", ratio: 0.332 },
              { color: "#6735be", ratio: 0.415 },
              { color: "#7139d4", ratio: 0.498 },
              { color: "#7b3ce9", ratio: 0.581 },
              { color: "#853fff", ratio: 0.664 },
              { color: "#a46fbf", ratio: 0.747 },
              { color: "#c29f80", ratio: 0.83 },
              { color: "#e0cf40", ratio: 0.913 },
              { color: "#ffff00", ratio: 1 },
            ],
            maxPixelIntensity: 25,
            minPixelIntensity: 0,
          };

          const layer2 = new CSVLayer({
            url: url2,
            title: "Earthquakes",
            copyright: "GEMAS",
            popupTemplate: template,
            renderer: renderer2,
          });

          const map2 = new Map({
            basemap: "gray-vector",
            layers: [layer2],
          });

          const view2 = new MapView({
            container: "map2",
            center: [107, -7],
            zoom: 6,
            map: map2,
          });

          view2.ui.add(
            new Legend({
              view: view2,
            }),
            "bottom-left"
          );
        });
      }
    },
  },
};
</script>
